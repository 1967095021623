import { validateEmail } from '@signup/shared';
import { ensureUnreachable } from '@signup/shared/src/utils/ensure';
import { ValidationResponse } from '@snowflake/core-ui';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { usingNewSignupForm } from '../../../utils/configurations';

export function useEmailValidator() {
  const { formatMessage } = useIntl();

  const validateEmailHelper = useCallback(
    (value: string | null): ValidationResponse => {
      const result = validateEmail(value);
      const isV2 = usingNewSignupForm();
      switch (result) {
        case 'success':
          return true;
        case 'error_invalid':
          return isV2 ? formatMessage({ id: 'Enter a valid email address' }) : false;
        case 'error_empty':
          return isV2 ? formatMessage({ id: 'This field is required' }) : false;
        case 'error_supported_characters':
          return formatMessage({ id: 'Email contains unsupported characters' });
        default:
          ensureUnreachable(result);
      }
    },
    [formatMessage],
  );

  return { validateEmailHelper };
}
