import {
  AwsFormFields,
  AwsMarketplaceType,
  AzureActivateSubscriptionPayload,
  Cloud,
  countriesKeyedByIsoCode,
  Edition,
  RawSignupFields,
  SignupFlowType,
  SignupRequestResponse,
} from '@signup/shared';
import { MarketingParams, SignupParams } from '../utils/ParseQueryParams';
import { FullEvent } from '@snowflake/core-ui';
import { getSignupFlowTypeWithoutListener } from '../utils/configurations';

type SupportedRequestInit = Pick<RequestInit, 'method' | 'body'> & {
  headers: Record<string, string> | undefined;
};

function injectHeaders(
  headers: Record<string, string> | undefined,
): Record<string, string> | undefined {
  const injectedHeaders = {
    'signup-flow-type': getSignupFlowTypeWithoutListener(),
  };
  if (!headers) {
    return injectedHeaders;
  }

  return {
    ...headers,
    ...injectedHeaders,
  };
}

async function fetchSignupApi(url: string, init: SupportedRequestInit): Promise<Response> {
  return fetch(url, {
    ...init,
    headers: injectHeaders(init.headers),
  });
}

export class SignupApi {
  static prepareSignupFields({
    firstName,
    lastName,
    email,
    company,
    phoneNumber,
    role,
    edition,
    cloud,
    region,
    awsType,
    signupParams,
    formId,
    countryIsoCode,
    recaptchaToken,
    optOutEmailAgreement,
    givenEmailOptInChoice,
    marketingParams,
    formTwoCompletionTime,
    optInEmailAgreement,
  }: {
    firstName: string;
    lastName: string;
    email: string;
    company: string;
    role: string;
    edition: Edition;
    phoneNumber?: string;
    cloud: Cloud;
    region: string;
    awsType?: AwsMarketplaceType;
    signupParams: SignupParams;
    formId: string;
    countryIsoCode: string;
    recaptchaToken: string;
    optOutEmailAgreement?: boolean;
    givenEmailOptInChoice?: boolean;
    marketingParams: MarketingParams;
    formTwoCompletionTime: number;
    optInEmailAgreement: boolean;
  }): RawSignupFields {
    return {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email.trim().toLowerCase(),
      company: company.trim(),
      phoneNumber,
      role: role,
      edition,
      cloud: cloud, // we know its going to be set here cause of validation
      region: region, // we know its going to be set here cause of validation
      country: countriesKeyedByIsoCode[countryIsoCode].value,
      recaptchaToken,
      ...(awsType !== undefined &&
        signupParams.ampt !== undefined && {
          ampt: signupParams.ampt,
          awsType: awsType,
        }),
      ...(optOutEmailAgreement !== undefined && { optOutEmailAgreement }), // should only be undefined for 'United States'
      ...(givenEmailOptInChoice && { givenEmailOptInChoice, optInEmailAgreement }),
      ...(signupParams.owner && { owner: signupParams.owner }),
      ...(signupParams.eng && { eng: signupParams.eng }),
      ...(signupParams.sdm && { sdm: signupParams.sdm }),
      ...(getSignupFlowTypeWithoutListener() === SignupFlowType.Developer
        ? { developers: true }
        : {}),
      ...(signupParams.listing && { listing: signupParams.listing }),
      ...(signupParams.lab && { lab: signupParams.lab }),
      ...(signupParams.referrer && { referrer: signupParams.referrer }),
      ...(signupParams.abexperiment && { abexperiment: signupParams.abexperiment }),
      ...(signupParams.account && { account: signupParams.account }),
      ...(signupParams.tutorial && { tutorial: signupParams.tutorial }),
      ...marketingParams,
      signupUrl: window.location.href,
      formId: formId,
      formTwoCompletionTime,
    };
  }

  static async createTrial(options: RawSignupFields): Promise<{
    res: SignupRequestResponse;
    headers: Headers;
  }> {
    const rawRes = await fetchSignupApi('/api/v1/createtrial', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options),
    });
    return {
      headers: rawRes.headers,
      res: await rawRes.json(),
    };
  }

  static async addAwsSubscription(fields: AwsFormFields): Promise<Response> {
    return fetchSignupApi('/api/v1/aws_add_subscription', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(fields),
    });
  }

  static async activateAzureSubscription(
    fields: AzureActivateSubscriptionPayload,
  ): Promise<Response> {
    return fetchSignupApi('/azure/activate_subscription', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(fields),
    });
  }

  static async resolveAzurePurchaseToken(token: string | string[]): Promise<Response> {
    return fetchSignupApi('/azure/resolvepurchasetoken', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token }),
    });
  }

  static async getListingInformation(listingId: string): Promise<Response> {
    return fetchSignupApi(`/api/v1/listing/${listingId}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  static async getDeploymentMetadataByListingGlobalId(listingGlobalId: string): Promise<Response> {
    return fetchSignupApi(`/api/v1/listing-available-regions/${listingGlobalId}`, {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }

  static async sendTelemetry(events: FullEvent[]): Promise<Response> {
    return fetchSignupApi('/api/v1/telemetry', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(events),
    });
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/Beacon_API/Using_the_Beacon_API
  static async sendTelemetryWithBeacon(events: FullEvent[]): Promise<void> {
    // Beacon API doesn't support passing headers: everything of the original request is marshaled into the request payload.
    const payload = {
      body: events,
      headers: injectHeaders(undefined),
    };

    const blob = new Blob([JSON.stringify(payload)], { type: 'application/json' });
    navigator.sendBeacon('/api/v1/telemetry_beacon', blob);
  }
}
