import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { FormInputsConfig, TextInput } from './SignupFormInputs';
import { SignupReason } from './SignupCardV2';
import { Cloud, Edition } from '@signup/shared';
import { useSignupFormData } from './SignupFormDataContext';
import { useSignupFormController } from './SignupFormControllerContext';
import { useSortedRegionSelectList } from './regionGroupingConfigs';
import { useIsPhoneNumberRequired } from '../../utils/SignupCountryUtils';
import { parseQueryParams } from '../../../../utils/ParseQueryParams';

export function useStepOneInputConfigs(): FormInputsConfig {
  const { formatMessage } = useIntl();
  const isPhoneNumberRequired = useIsPhoneNumberRequired();

  const inputConfigs: FormInputsConfig = useMemo(
    () => [
      [
        {
          title: formatMessage({ id: 'First name' }),
          fieldName: 'firstName',
          type: 'text',
        },
        {
          title: formatMessage({ id: 'Last name' }),
          fieldName: 'lastName',
          type: 'text',
        },
      ],
      [
        {
          title: formatMessage({ id: 'Work email' }),
          fieldName: 'email',
          type: 'text',
        },
      ],
      ...(isPhoneNumberRequired
        ? [
            [
              {
                title: formatMessage({ id: 'Phone number' }),
                fieldName: 'phoneNumber',
                type: 'text',
              } as TextInput,
            ],
          ]
        : []),
      [
        {
          title: formatMessage({ id: 'Why are you signing up?' }),
          fieldName: 'signupReason',
          type: 'selector',
          options: [
            {
              value: SignupReason.CompanyConsidering,
              label: formatMessage({ id: 'Company is considering Snowflake' }),
            },
            {
              value: SignupReason.JobTraining,
              label: formatMessage({ id: 'Training or certification for my job' }),
            },
            {
              value: SignupReason.PersonalTraining,
              label: formatMessage({ id: 'Personal learning and development' }),
            },
            {
              value: SignupReason.Student,
              label: formatMessage({ id: 'I’m a student exploring Snowflake' }),
            },
            {
              value: SignupReason.Other,
              label: formatMessage({ id: 'Other' }),
            },
          ],
        },
      ],
    ],
    [formatMessage, isPhoneNumberRequired],
  );

  return inputConfigs;
}

export function useStudentInputConfigs(): FormInputsConfig {
  const { formatMessage } = useIntl();

  return useMemo(
    () => [
      [
        {
          title: formatMessage({ id: 'School name' }),
          fieldName: 'schoolName',
          type: 'text',
        },
      ],
    ],
    [formatMessage],
  );
}

export function useNonStudentInputConfigs(): FormInputsConfig {
  const { formatMessage } = useIntl();

  return useMemo(
    () => [
      [
        {
          title: formatMessage({ id: 'Company name' }),
          fieldName: 'companyName',
          type: 'text',
        },
      ],
      [
        {
          title: formatMessage({ id: 'Job title' }),
          fieldName: 'jobTitle',
          type: 'text',
        },
      ],
    ],
    [formatMessage],
  );
}

export function useEditionAndCloudInputConfigs(): FormInputsConfig {
  const { formatMessage } = useIntl();
  const { cloud } = useSignupFormData();
  const { regionSelectList } = useSignupFormController();
  const groupedRegionSelectList = useSortedRegionSelectList();

  const { lockedCloud, lockedRegion } = useMemo(() => {
    const [signupParams, _] = parseQueryParams(window.location.search);
    const { cloud: lockedCloud, region: lockedRegion } = signupParams;
    return { lockedCloud, lockedRegion };
  }, []);

  const logos: { [cloud in Cloud]: { src: string; title: string } } = useMemo(() => {
    return {
      [Cloud.AWS]: {
        src: '/static/images/cloud_provider_choices/aws-logo.svg',
        title: formatMessage({ id: 'Amazon Web Services' }),
      },
      [Cloud.GCP]: {
        src: '/static/images/cloud_provider_choices/gcp-logo.png',
        title: formatMessage({ id: 'Google Cloud Platform' }),
      },
      [Cloud.AZURE]: {
        src: '/static/images/cloud_provider_choices/microsoft-logo.svg',
        title: formatMessage({ id: 'Microsoft Azure' }),
      },
    };
  }, [formatMessage]);

  const cloudConfigs: FormInputsConfig = [
    [
      {
        title: formatMessage({ id: 'Choose your Snowflake edition' }),
        rightHandSideFloater: {
          text: formatMessage({ id: 'Learn more' }),
          link: 'https://www.snowflake.com/en/data-cloud/pricing-options/#pricingEditions',
        },
        fieldName: 'edition',
        type: 'selector',
        options: [
          {
            value: Edition.STANDARD,
            label: formatMessage({ id: 'Standard' }),
            sublabel: formatMessage({
              id: 'A strong balance between features, level of support, and cost',
            }),
          },
          {
            value: Edition.ENTERPRISE,
            label: formatMessage({ id: 'Enterprise (Most popular)' }),
            sublabel: formatMessage({
              id: 'Standard plus 90-day time travel, multi-cluster warehouses, and materialized views',
            }),
          },
          {
            value: Edition.BUSINESS_CRITICAL,
            label: formatMessage({ id: 'Business Critical' }),
            sublabel: formatMessage({
              id: 'Enterprise plus enhanced security, data protection, and database fallover/fallback',
            }),
          },
        ],
      },
    ],
    [
      {
        title: lockedCloud
          ? formatMessage({ id: 'Cloud provider' })
          : formatMessage({ id: 'Choose your cloud provider' }),
        subtitle: formatMessage({
          id: 'Snowflake runs on top of the cloud platform you’re already working with.',
        }),
        fieldName: 'cloud',
        type: 'cloud',
        options: lockedCloud
          ? [
              {
                src: logos[lockedCloud].src,
                title: logos[lockedCloud].title,
                value: lockedCloud,
              },
            ]
          : [
              {
                src: logos[Cloud.AZURE].src,
                title: logos[Cloud.AZURE].title,
                value: Cloud.AZURE,
              },
              {
                src: logos[Cloud.AWS].src,
                title: logos[Cloud.AWS].title,
                value: Cloud.AWS,
              },
              {
                src: logos[Cloud.GCP].src,
                title: logos[Cloud.GCP].title,
                value: Cloud.GCP,
              },
            ],
      },
    ],
  ];

  if (cloud) {
    cloudConfigs.push([
      {
        title: formatMessage({ id: 'Region' }),
        fieldName: 'region',
        type: 'selector',
        options: lockedRegion
          ? regionSelectList[cloud].filter(regionOption => regionOption.value === lockedRegion)
          : groupedRegionSelectList || regionSelectList[cloud],
      },
    ]);
  }

  return cloudConfigs;
}
