import { default as React, useState, useCallback, useMemo, useEffect } from 'react';
import { BaseCardProps } from '../../shared/shared';
import { SignupFormDataProvider } from './SignupFormDataContext';
import { ensureUnreachable } from '@signup/shared/src/utils/ensure';
import { SignupFormStepOne } from './SignupFormStepOne';
import { SignupFormStepTwo } from './SignupFormStepTwo';
import { SignupSurveyCard } from './SignupSurveyCard';
import { SignupCompleteCard } from './SignupCompleteCard';
import {
  SignupFormControllerProvider,
  useSignupFormController,
} from './SignupFormControllerContext';
import { useLogOnClose } from '../../../UseLogOnClose';
import { SignupEventName } from '../../../../utils/SignupLogger';
import { useSignupPageContext } from '../../../../pages/SignupContext';

export enum SignupReason {
  CompanyConsidering = 'Company is considering Snowflake',
  JobTraining = 'Training or Certification',
  PersonalTraining = 'Personal learning and development',
  Student = 'Student',
  Other = 'Other',
}

enum SignupCardStage {
  SignupForm = 'SignupForm',
  Survey = 'Survey',
}

enum SignupCardStep {
  SignupFormOne = 'SignupFormOne',
  SignupFormTwo = 'SignupFormTwo',
  Survey = 'Survey',
  Completed = 'Completed',
}

type SignupCardState =
  | {
      stage: SignupCardStage.SignupForm;
      step: SignupCardStep.SignupFormOne | SignupCardStep.SignupFormTwo;
    }
  | {
      stage: SignupCardStage.Survey;
      step: SignupCardStep.Survey | SignupCardStep.Completed;
    };

function useNavigationControl() {
  const [state, setState] = useState<SignupCardState>({
    stage: SignupCardStage.SignupForm,
    step: SignupCardStep.SignupFormOne,
  });

  const [currentStateStartTime, setCurrentStateStartTime] = useState<number>(Date.now() / 1000);

  useEffect(() => {
    setCurrentStateStartTime(Date.now() / 1000);
  }, [state.stage, state.step]);

  const { renderingOptions } = useSignupPageContext();

  const continueNext = useCallback(() => {
    switch (state.stage) {
      case SignupCardStage.SignupForm:
        switch (state.step) {
          case SignupCardStep.SignupFormOne:
            return setState({
              stage: SignupCardStage.SignupForm,
              step: SignupCardStep.SignupFormTwo,
            });
          case SignupCardStep.SignupFormTwo:
            if (renderingOptions.skipSurvey) {
              return setState({ stage: SignupCardStage.Survey, step: SignupCardStep.Completed });
            }
            return setState({ stage: SignupCardStage.Survey, step: SignupCardStep.Survey });
          default:
            ensureUnreachable(state);
        }
      case SignupCardStage.Survey:
        switch (state.step) {
          case SignupCardStep.Survey:
            return setState({ stage: SignupCardStage.Survey, step: SignupCardStep.Completed });
          case SignupCardStep.Completed:
            return null;
          default:
            ensureUnreachable(state);
        }
      default:
        ensureUnreachable(state);
    }
  }, [state, setState, renderingOptions.skipSurvey]);

  const goBack = useCallback(() => {
    switch (state.stage) {
      case SignupCardStage.SignupForm:
        switch (state.step) {
          case SignupCardStep.SignupFormOne:
            return null;
          case SignupCardStep.SignupFormTwo:
            return setState({
              stage: SignupCardStage.SignupForm,
              step: SignupCardStep.SignupFormOne,
            });
          default:
            ensureUnreachable(state);
        }
      case SignupCardStage.Survey:
        switch (state.step) {
          case SignupCardStep.Survey:
            return null;
          case SignupCardStep.Completed:
            return null;
          default:
            ensureUnreachable(state);
        }
      default:
        ensureUnreachable(state);
    }
  }, [state, setState]);

  const restartWithoutClearInputs = useCallback(() => {
    setState({
      stage: SignupCardStage.SignupForm,
      step: SignupCardStep.SignupFormOne,
    });
  }, [setState]);

  const exitEvent = useMemo((): SignupEventName => {
    switch (state.stage) {
      case SignupCardStage.SignupForm:
        switch (state.step) {
          case SignupCardStep.SignupFormOne:
            return SignupEventName.EXIT_SIGNUP_FIRST_FORM;
          case SignupCardStep.SignupFormTwo:
            return SignupEventName.EXIT_SIGNUP_SECOND_FORM;
          default:
            ensureUnreachable(state);
        }
      case SignupCardStage.Survey:
        switch (state.step) {
          case SignupCardStep.Survey:
            return SignupEventName.EXIT_SIGNUP_SURVEY_PAGE;
          case SignupCardStep.Completed:
            return SignupEventName.EXIT_SIGNUP_THANK_YOU_PAGE;
          default:
            ensureUnreachable(state);
        }
      default:
        ensureUnreachable(state);
    }
  }, [state]);

  return {
    state,
    continueNext,
    goBack,
    restartWithoutClearInputs,
    exitEvent,
    currentStateStartTime,
  };
}

const SignupCardV2Impl = () => {
  const {
    state,
    continueNext,
    goBack,
    restartWithoutClearInputs,
    exitEvent,
    currentStateStartTime,
  } = useNavigationControl();
  const { clearCreationErrorAndResetRecaptcha } = useSignupFormController();

  useLogOnClose({ message: exitEvent, startTime: currentStateStartTime });

  const onTryAgain = useCallback(() => {
    restartWithoutClearInputs();
    clearCreationErrorAndResetRecaptcha();
  }, [restartWithoutClearInputs, clearCreationErrorAndResetRecaptcha]);

  const render = useCallback(() => {
    switch (state.stage) {
      case SignupCardStage.SignupForm:
        switch (state.step) {
          case SignupCardStep.SignupFormOne:
            return <SignupFormStepOne continueNext={continueNext} />;
          case SignupCardStep.SignupFormTwo:
            return <SignupFormStepTwo continueNext={continueNext} goBack={goBack} />;
          default:
            ensureUnreachable(state);
        }
      case SignupCardStage.Survey:
        switch (state.step) {
          case SignupCardStep.Survey:
            return <SignupSurveyCard continueNext={continueNext} />;
          case SignupCardStep.Completed:
            return <SignupCompleteCard onTryAgain={onTryAgain} />;
          default:
            ensureUnreachable(state);
        }
      default:
        ensureUnreachable(state);
    }
  }, [state, continueNext, goBack, onTryAgain]);

  return render();
};

export const SignupCardV2 = (props: BaseCardProps) => {
  return (
    <SignupFormDataProvider>
      <SignupFormControllerProvider {...props}>
        <SignupCardV2Impl />
      </SignupFormControllerProvider>
    </SignupFormDataProvider>
  );
};
