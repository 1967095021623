import * as React from 'react';
import { Text, Row, Color } from '@snowflake/core-ui';
import { SignupFlowType } from '@signup/shared';
import { getCurrentRootUrl } from '../components/Cards/shared/shared';
import { FormattedMessage, useIntl } from 'react-intl';
import { ensureUnreachable } from '@signup/shared/src/utils/ensure';
import { MarketingTextType, RenderingOptions } from '../types';
import { useSignupFlowType } from './useSignupFlowType';

export const useRenderingOptions = (locale: string): RenderingOptions => {
  const signupFlowType = useSignupFlowType();
  const { formatMessage } = useIntl();

  return React.useMemo<RenderingOptions>(() => {
    const defaultRenderingOptions: RenderingOptions = {
      marketingTextType: MarketingTextType.BASIC,
      mainMarketingTitle:
        locale === 'en-US' || locale === 'ko' ? (
          <>
            <FormattedMessage
              id="Start your<linebreak></linebreak>30-day free trial"
              values={{
                linebreak: function LineBreak() {
                  return <br />;
                },
              }}
            />
          </>
        ) : (
          <FormattedMessage id="Start your 30-day free trial" />
        ),
      marketingInfoList: [
        <FormattedMessage key="1" id="Gain immediate access to the AI Data Cloud" />,
        <FormattedMessage key="2" id="Enable your most critical data workloads" />,
        <FormattedMessage
          key="3"
          id="Scale instantly, elastically, and near-infinitely across public clouds"
        />,
      ],
      cardMarketingTitle: (
        <FormattedMessage id="Start your 30-day free Snowflake trial which includes $400 worth of free usage" />
      ),
    };

    const studentRenderingOptions: RenderingOptions = {
      marketingTextType: MarketingTextType.BASIC,
      mainMarketingTitle: (
        <>
          <FormattedMessage
            id="Start your<linebreak></linebreak>120-day free trial"
            values={{
              linebreak: function LineBreak() {
                return <br />;
              },
            }}
          />
        </>
      ),
      marketingInfoList: defaultRenderingOptions.marketingInfoList,
      cardMarketingTitle: (
        <FormattedMessage id="Start your 120-day free Snowflake trial which includes $400 worth of free usage" />
      ),
    };

    switch (signupFlowType) {
      case SignupFlowType.Default:
      case SignupFlowType.AccountExecutive:
      case SignupFlowType.Unknown:
        /**
         * Default signup flow.
         * For typing purposes the Unknown signup flow is also considered default,
         * but it should be handled as a page not found.
         */
        return defaultRenderingOptions;
      case SignupFlowType.Developer:
        /**
         * Used by marketing team to target developer users.
         */
        return {
          marketingTextType: MarketingTextType.BASIC,
          mainMarketingTitle: defaultRenderingOptions.mainMarketingTitle,
          cardMarketingTitle: (
            <FormattedMessage id="Build on Snowflake with $400 of credits to develop apps" />
          ),
          marketingInfoList: [
            <FormattedMessage key="1" id="Code in Python, SQL, Java, or Scala" />,
            <FormattedMessage
              key="2"
              id="Build with structured, semi-structured, or unstructured data"
            />,
            <FormattedMessage
              key="3"
              id="Single platform for data engineering, application development, data science, and more"
            />,
          ],
          hideCertifications: true,
        } as RenderingOptions;
      case SignupFlowType.Lab:
        return {
          marketingTextType: MarketingTextType.BASIC,
          mainMarketingTitle: (
            <FormattedMessage id="Try Snowpark for python with free 30-day trial" />
          ),
          cardMarketingTitle: defaultRenderingOptions.cardMarketingTitle,
          marketingInfoList: [
            <FormattedMessage key="1" id="Create pipelines in Python with Snowpark" />,
            <FormattedMessage
              key="2"
              id="One-click tutorial setup with preconfigured environment"
            />,
            <FormattedMessage key="3" id="Sample code with data transformations in Python" />,
            <FormattedMessage key="4" id="Free, no additional cloud provider accounts required" />,
          ],
          hideCertifications: true,
        };
      case SignupFlowType.Startup:
        return {
          marketingTextType: MarketingTextType.BASIC,
          cardMarketingTitle: (
            <FormattedMessage id="Start your free Snowflake trial which includes $400 worth of free usage" />
          ),
          marketingInfoList: defaultRenderingOptions.marketingInfoList,
          mainMarketingTitle: (
            <>
              <FormattedMessage
                id="Start your <linebreak></linebreak> free trial"
                values={{
                  linebreak: function LineBreak() {
                    return <br />;
                  },
                }}
              />
            </>
          ),
        };
      case SignupFlowType.SalesAssisted:
        return {
          ...defaultRenderingOptions,
          skipSurvey: true,
          showSalesAccessCheckbox: true,
          successTitle: formatMessage({ id: "We're working on it" }),
          successSubtitle: (
            <FormattedMessage id="Your account is being setup. A Snowflake representative will send your activation link shortly. In the meantime, please use these resources to get started." />
          ),
        };
      case SignupFlowType.Student:
        return studentRenderingOptions;
      case SignupFlowType.Learn:
        return { ...studentRenderingOptions, showEmailAgreementCheckbox: true };
      case SignupFlowType.AwsOnDemand:
        return {
          marketingTextType: MarketingTextType.BASIC,
          awsAddPaymentLink: (
            <>
              <Row align="center" verticalAlign="top" marginTop={16}>
                <Text size="small" color={Color.Gray70} textAlign="center">
                  <FormattedMessage
                    id="Already have a Snowflake account? <existingaccount>Add AWS payment option</existingaccount>"
                    values={{
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      existingaccount: function ExistingAccount(...chunks: any[]) {
                        return (
                          <>
                            <br />
                            <a
                              href={`${getCurrentRootUrl()}/aws/add-subscription${
                                window.location.search
                              }`}
                              style={{ color: Color.Gray70 }}
                            >
                              {chunks}
                            </a>
                          </>
                        );
                      },
                    }}
                  />
                </Text>
              </Row>
            </>
          ),
          cardMarketingTitle: (
            <FormattedMessage id="Please fill out the following information to complete your Snowflake subscription!" />
          ),

          mainMarketingTitle: <FormattedMessage id="Start your subscription" />,
          marketingInfoList: [
            <FormattedMessage key="1" id="Data-driven business decisions" />,
            <FormattedMessage key="2" id="Exponential analytics performance and cost savings" />,
            <FormattedMessage key="3" id="360-degree customer views and experiences" />,
          ],
          hideLoginLink: true,
        };
      case SignupFlowType.AwsOnDemandAddSubscription:
        /**
         * AWS On Demand Add Subscription flow uses default rendering options with the AWS Card.
         */
        return defaultRenderingOptions;
      case SignupFlowType.AwsCapacity:
        return {
          skipSurvey: true,
          marketingTextType: MarketingTextType.OMIT,
        };
      case SignupFlowType.Azure:
      case SignupFlowType.Gcp:
        return {
          marketingTextType: MarketingTextType.OMIT,
        };
      case SignupFlowType.Listing:
        return {
          marketingTextType: MarketingTextType.LISTING,
          cardMarketingTitle: defaultRenderingOptions.cardMarketingTitle,
          skipSurvey: true,
        };
      case SignupFlowType.Streamlit:
        return {
          ...defaultRenderingOptions,
          skipSurvey: true,
        };
      default:
        ensureUnreachable(signupFlowType);
    }
  }, [signupFlowType, formatMessage, locale]);
};
