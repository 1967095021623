import { useContext } from 'react';
import { SignupFormDataContext } from '../forms/SignupCardV2/SignupFormDataContext';
import { isFeatureEnabled } from '../../../utils/configurations';
import { FeatureFlag } from '@signup/shared';

const GDPR_COUNTRY_ISO_CODES: string[] = ['CY', 'GR', 'DE', 'IT', 'LT', 'NO', 'PL'];

// Asia pacific region country codes
const APJ_COUNTRY_ISO_CODES: string[] = [
  'AU',
  'NZ',
  'IN',
  'SG',
  'PH',
  'TH',
  'MY',
  'ID',
  'KR',
  'JP',
  'PK',
  'LK',
  'NP',
  'CN',
  'HK',
  'TW',
  'BD',
  'UZ',
  'KZ',
  'MN',
  'KH',
  'PG',
  'BT',
  'WS',
  'MV',
  'NC',
  'FJ',
  'TO',
];

export const isCountryISOCodeInAPJ = (countryISOCode: string): boolean => {
  return APJ_COUNTRY_ISO_CODES.includes(countryISOCode);
};

export const isCountryISOCodeInGDPR = (countryISOCode: string): boolean => {
  return GDPR_COUNTRY_ISO_CODES.includes(countryISOCode);
};

export const isCountryISOCodeInUS = (countryISOCode: string): boolean => {
  return countryISOCode == 'US';
};

export function useIsPhoneNumberRequired(): boolean {
  const { countryIsoCode } = useContext(SignupFormDataContext)!;

  return countryIsoCode === 'JP' && isFeatureEnabled(FeatureFlag.FF_ENABLE_JAPAN_PHONE_NUMBER);
}
