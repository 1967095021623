import { Color, Checkbox, Column, Row, RowProps, Text } from '@snowflake/core-ui';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { usingNewSignupForm } from '../../../utils/configurations';

export interface EmailOptOutAgreementProps extends RowProps {
  optOutEmailAgreement?: boolean;
  optOutAgreementOnChangeHandler: (optOut?: boolean) => void;
}

export const EmailOptOutAgreement = (props: EmailOptOutAgreementProps) => {
  const { optOutEmailAgreement, optOutAgreementOnChangeHandler, ...restProps } = props;

  const message = usingNewSignupForm() ? (
    <FormattedMessage id="I want to opt out of Snowflake emails about products, services, and events." />
  ) : (
    <FormattedMessage
      id="No, I do <uppercase>not</uppercase> want Snowflake to send me e-mails about products, services, and events
              that it thinks may interest me."
      values={{
        uppercase: function UpperCase(...chunks: string[]) {
          return <span style={{ textTransform: 'uppercase' }}>{chunks}</span>;
        },
      }}
    />
  );

  return (
    <Row align="left" verticalAlign="top" {...restProps} marginBottom={8} marginTop={12}>
      <Column align="left" verticalAlign="top" marginRight={8}>
        <Checkbox
          name="opt-out-agreement"
          aria-label="opt-out-agreement"
          checked={optOutEmailAgreement}
          value="opt-out-agreement"
          onChange={() => optOutAgreementOnChangeHandler(optOutEmailAgreement)}
        />
      </Column>
      <Column align="left" verticalAlign="top">
        <Text size="small" color={Color.Gray70}>
          {message}
        </Text>
      </Column>
    </Row>
  );
};
EmailOptOutAgreement.displayName = 'EmailOptOutAgreement';
